import { Col, Modal, Row } from "antd";
import { Component } from "react";
import { LoadablePointCloud } from "./types/LoadablePointCloud";


interface State {
    pointClouds : LoadablePointCloud[],
    selected?: LoadablePointCloud,
    loadedPointCloudURL: string; 
    visible: boolean,
}

interface Props {
    onLoad: (pointCloud: LoadablePointCloud) => void
}

export default class LoadPointCloudModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            pointClouds: [
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/Helena_MT2024/10162024A`,
                    name: "Helena addendum lidar file 1",
                    description: "file 1 of new lidar data provided by helena",
                    date_uploaded: new Date(`11/21/2024`),
                    data_identifier: 'helenamt',
                    collector: 'NV5',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/Helena_MT2024/10162024B`,
                    name: "Helena addendum lidar file 2",
                    description: "file 2 of new lidar data provided by helena",
                    date_uploaded: new Date(`11/21/2024`),
                    data_identifier: 'helenamt',
                    collector: 'NV5',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/Helena_MT2024`,
                    name: "Helena addendum lidar file 3",
                    description: "file 3 of new lidar data provided by helena",
                    date_uploaded: new Date(`11/21/2024`),
                    data_identifier: 'helenamt',
                    collector: 'NV5',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/all_lidar`,
                    name: "LA Pilot All Data",
                    description: "Scan of all sample LA ",
                    date_uploaded: new Date(`9/26/2024`),
                    data_identifier: 'la_pilot',
                    collector: 'NV5',
                    projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot`,
                    name: "LA Pilot PA_06",
                    description: "Scan of sample LA - San Rafael Ave from Mount Washington Dr to W Ave 37th & W Ave 37th from San Rafael Ave to Mayfair Dr",
                    date_uploaded: new Date(`9/26/2024`),
                    data_identifier: 'la_pilot',
                    collector: 'NV5',
                    projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/PA_01_05_10`,
                    name: "LA Pilot PA-00005, PA-00001, and PA-00010",
                    description: "Scan of sample LA: S Flower St from W 12th St to Venice Blvd, Grand Ave from Pico Blvd to Olympic Blvd, S Flower St from Venice to West 9th St,",
                    date_uploaded: new Date(`9/28/2024`),
                    data_identifier: 'la_pilot',
                    collector: 'NV5',
                    projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/PA_09`,
                    name: "LA Pilot PA_09",
                    description: "Scan of sample LA: North Ave 52 from Grananda St to Monte Vista Street",
                    date_uploaded: new Date(`9/28/2024`),
                    data_identifier: 'la_pilot',
                    collector: 'NV5',
                    projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/la_pilot/PA_20`,
                    name: "LA Pilot PA_20",
                    description: "Scan of sample LA:City Hall One Mile Route",
                    date_uploaded: new Date(`9/28/2024`),
                    data_identifier: 'la_pilot',
                    collector: 'NV5',
                    projection: "+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs +type=crs",

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/minessota`,
                    name: "Minessota lidar data",
                    description: "Scan of sample Minessota",
                    date_uploaded: new Date(`4/12/2024`),
                    data_identifier: 'minessota',
                    collector: 'NV5',
                    projection:'+proj=utm +zone=15 +ellps=GRS80 +datum=NAD83 +units=m +no_defs',

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/minnesota_hastings`,
                    name: "Hastings Minessota lidar data",
                    description: "Scan of sample Minessota",
                    date_uploaded: new Date(`4/12/2024`),
                    data_identifier: 'minessota',
                    collector: 'NV5',
                    projection:'+proj=utm +zone=15 +ellps=GRS80 +datum=NAD83 +units=m +no_defs',

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/stapleton-2.1`,
                    name: "Stapleton, NY",
                    description: "A two pass scan of Stapleton alongside the shore.",
                    date_uploaded: new Date(`10/13/2021`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_1=40.66666666666666 +lat_2=41.03333333333333 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_def`,
                },
                // {
                //     url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-clarke`,
                //     name: "Clarke Street",
                //     description: "A two pass scan of Clarke.",
                //     date_uploaded: new Date(`11/08/2023`),
                //     data_identifier: 'helenamt',
                // collector: 'tectonic', 
                // projection: `+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +to_meter=1 +no_defs +type=crs`,
                // },
                // {
                //     url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-rodney`,
                //     name: "Helena Rodney",
                //     description: "A two pass scan of rodney.",
                //     date_uploaded: new Date(`11/08/2023`),
                //     data_identifier: 'helenamt',
                // collector: 'tectonic',   
                // projection: `+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +to_meter=1 +no_defs +type=crs`,
                // },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-19`,
                    name: "Helena September 19",
                    description: "Scan of Helena tracks on september 19",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-20-b`,
                    name: "Helena September 20th",
                    description: "Scan of Helena tracks on september 20",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/WTC Busway`,
                    name: "WTC Busway",
                    description: "WTC Busway scan",
                    date_uploaded: new Date(`11/28/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',// 
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-22`,
                    name: "Helena September 22",
                    description: "Scan of Helena tracks on September 22",
                    date_uploaded: new Date(`11/17/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-23`,
                    name: "Helena September 23 version a",
                    description: "Scan of Helena tracks on September 23 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-23-b`,
                    name: "Helena September 23 version b",
                    description: "Scan of Helena tracks on September 23 version b",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-24-a`,
                    name: "Helena September 24 version a",
                    description: "Scan of Helena tracks on September 24 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-25-a`,
                    name: "Helena September 25 version a",
                    description: "Scan of Helena tracks on September 25 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-25-b`,
                    name: "Helena September 25 version b",
                    description: "Scan of Helena tracks on September 25 version b",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-26-a`,
                    name: "Helena September 26 version a",
                    description: "Scan of Helena tracks on September 26 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-27-a`,
                    name: "Helena September 27 version a",
                    description: "Scan of Helena tracks on September 27 version a",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-27-b`,
                    name: "Helena September 27 version b",
                    description: "Scan of Helena tracks on September 27 version b",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-28-a`,
                    name: "Helena September 28 version a",
                    description: "Scan of Helena tracks on September 28 version a",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-sep-28-b`,
                    name: "Helena September 28 version b",
                    description: "Scan of Helena tracks on September 28 version b",
                    date_uploaded: new Date(`11/15/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-oct-2-a`,
                    name: "Helena October 2 version a",
                    description: "Scan of Helena tracks on October 2 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-oct-2-b`,
                    name: "Helena October 2 version b",
                    description: "Scan of Helena tracks on October 2 version b",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-oct-3-a`,
                    name: "Helena October 3 version a",
                    description: "Scan of Helena tracks on October 3 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-oct-3-b`,
                    name: "Helena October 3 version b",
                    description: "Scan of Helena tracks on October 3 version b",
                    date_uploaded: new Date(`11/17/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-oct-5-a`,
                    name: "Helena October 5 version a",
                    description: "Scan of Helena tracks on October 5 version a",
                    date_uploaded: new Date(`11/13/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/1300Billings`,
                    name: "1300 Billings",
                    description: "Scan of 1300 Billings.",
                    date_uploaded: new Date(`11/09/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/helena-316NPark`,
                    name: "316 NPark",
                    description: "Scan of 316NPark.",
                    date_uploaded: new Date(`11/09/2023`),
                    data_identifier: 'helenamt',
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,

                },
                {
                    url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/long-island-sample-09262023`,
                    name: "Long Island Sample",
                    description: "Sample of LiDAR data from Long Island.",
                    data_identifier: 'helenamt',
                    date_uploaded: new Date(`10/02/2023`),
                    collector: 'tectonic',
                    projection: `+proj=lcc +lat_1=40.66666666666666 +lat_2=41.03333333333333 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_def`,
                },
            ],
            visible: false,
            selected: {
                url: `https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/Helena_MT2024/10162024A`,
                name: "Helena addendum lidar file 1",
                description: "file 1 of new lidar data provided by helena",
                date_uploaded: new Date(`11/21/2024`),
                data_identifier: 'helenamt',
                collector: 'NV5',
                projection: `+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs +type=crs`,
            },
            loadedPointCloudURL: "https://citian-public-v2.s3.us-east-2.amazonaws.com/lidar-samples/Helena_MT2024/10162024A"
        };
    }

    showModal() {
        this.setState({ visible: true });
    }

    hideModal() {
        this.setState({ visible: false });
    }

    onLoadButton() {
        if (this.state.selected) {
            this.props.onLoad(this.state.selected);
            this.setState({ visible: false,loadedPointCloudURL: this.state.selected.url });
        }
    }


    render() {
        return (
            <Modal
                title={"Load Point Cloud"}
                width={800}
                visible={this.state.visible}
                okText={"Load"}
                okButtonProps={{ disabled: this.state.selected == undefined }}
                onOk={()=>this.onLoadButton()}
                onCancel={()=>this.setState({ visible: false })}
            >
                <div style={{ height: 300 }}>
                    <Row style={{ height: "100%" }}>
                        <Col span={12} style={{ height: "100%" }}>
                            <div style={{ height: "100%", overflow: "auto", background: "#242424", margin: 8 }}>
                                {this.state.pointClouds.map((pc) =>
                                    <div
                                        key={pc.url}
                                        onClick={() => {
                                            // 只有當該點雲未加載時才允許點擊
                                            if (this.state.loadedPointCloudURL !== pc.url) {
                                                this.setState({ selected: pc });
                                            }
                                        }}
                                        style={{
                                            background: this.state.loadedPointCloudURL == pc.url
                                                ? "#444" // 已加載的點雲顯示灰色
                                                : this.state.selected?.url == pc.url
                                                ? "#177DDC" // 選中的點雲顯示藍色
                                                : "#141414", // 默認背景
                                            margin: 8,
                                            padding: 8,
                                            cursor: this.state.loadedPointCloudURL == pc.url ? "not-allowed" : "pointer", // 禁用點擊時改變鼠標樣式
                                            color: this.state.loadedPointCloudURL == pc.url ? "#888" : "#fff", // 已加載的文字顯示灰色
                                        }}
                                    >
                                        {pc.name}<i style={{ color: "gray", float: "right" }}>{pc.date_uploaded.toLocaleDateString()}</i>
                                    </div>,
                                )

                                }
                            </div>

                        </Col>
                        <Col span={12}>
                            <div style={{ width: "100%", height: "50%", background: "#242424", margin: 8, padding: 8 }}>
                                {this.state.selected === undefined ?
                                    <i>Select a point cloud to view map</i> :
                                    <i>Map currently unavailable.</i>}
                            </div>
                            <div style={{ width: "100%", height: "50%", background: "#242424", margin: 8, padding: 8 }}>
                                {this.state.selected === undefined ?
                                    <i>Select a point cloud to view description</i> :
                                    <>{this.state.selected.description}</>}
                            </div>
                        </Col>
                    </Row>


                </div>
            </Modal>

        );
    }
}
